<template>
    <el-dialog
            title="评价审核"
            :visible.sync="dialogVisible"
            width="800"
            :close-on-click-modal="false"
            :before-close="handleClose">

        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">
            <el-form-item label="评价人昵称" prop="userName" label-width="150px">
                <el-input v-model="editForm.userName" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="被评价人昵称" prop="tevaluationUserName" label-width="150px">
                <el-input v-model="editForm.tevaluationUserName" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="评价时间" prop="createTime" label-width="150px">
                <el-input v-model="editForm.createTime" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="评价星级" prop="evaluationScore" label-width="150px" >
                <div style="padding-top: 10px;">
                    <el-rate :value="editForm.evaluationScore" disabled></el-rate>
                </div>
            </el-form-item>
            <el-form-item label="评价标签" prop="evaluationLabel" label-width="150px">
                <el-input v-model="editForm.evaluationLabel" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="评价内容" prop="evaluationContent" label-width="150px">
                <el-input v-model="editForm.evaluationContent" autocomplete="off" disabled   type="textarea"   show-word-limit></el-input>
            </el-form-item>
            <el-form-item  align="center">
                <el-button type="primary" @click="updateEvaluation('editForm')">通过</el-button>
                <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        name: "EvaluationAudit",
        data(){
            return{
                editForm:{},
                dialogVisible:false,
            }
        },
        methods:{
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
            },
            handleClose() {
                this.resetForm('editForm')
            },
            init(id){
                this.dialogVisible=true;
                if (id!=null&&id!=''&&id>0){
                    this.$axios.post('/admin/evaluation/getEvaluationInfo' ,{tevaluationId:id}).then(res => {
                        this.editForm = res.data
                    })
                }
            },
            updateEvaluation(formName){
                this.$axios.post('/admin/evaluation/updateEvaluation', {tevaluationId:this.editForm.tevaluationId})
                    .then(res => {
                        this.$message({
                            showClose: true,
                            message: '恭喜你，操作成功',
                            type: 'success',
                            onClose:() => {
                                this.$emit('refreshDataList')
                            }
                        });
                        this.dialogVisible = false
                        this.resetForm(formName)
                    })
            },
        }
    }
</script>

<style scoped>

</style>
